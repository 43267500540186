.welcome {
  height: 95vh;
  min-height: 400px;

  header {
    position: absolute;
    z-index: 10;
    top: 0;
    display: flex;
    background-color: rgba(28, 26, 73, 0.5);
    align-items: center;
    padding: 1% 5%;
    width: 100%;
    box-shadow: 0 0 1vw black;

    img {
      width: 5vw;
    }

    a {
      width: 100%;
      text-decoration: none;
      text-align: right;

      h2 {
        font-size: 1.2vw;
      }
    }
  }

  article {
    position: relative;
    background-image: url('../../assets/screen_1/map.png');
    background-color: var(--background-main);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    height: 100%;
    position: relative;
    animation: background-animation 14s infinite;

    div {
      position: absolute;
      padding: 1%;
      bottom: 6%;
      left: 5%;

      h1 {
        text-shadow: 0 0 0.5vw #000129;
        animation: shadow-text-animation 14s infinite;
      }

      button {
        font-size: 3vw;
        padding: 1vw 2vw;
        border-radius: 1.5vw;
        box-shadow: 0 0 0.5vw #000129;
        animation: shadow-box-animation 14s infinite;
      }
    }
  }
}

@keyframes background-animation {
  50% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}

@keyframes shadow-text-animation {
  50% {
    text-shadow: -0.5vw -0.5vw 0.5vw #000129;
  }
  100% {
    text-shadow: 0 0 0.5vw #000129;
  }
}

@keyframes shadow-box-animation {
  50% {
    box-shadow: -0.5vw -0.5vw 0.5vw #000129;
  }
  100% {
    box-shadow: 0 0 0.5vw #000129;
  }
}

@media (max-width: 1600px) {
  .welcome {
    header {
      padding: 15px 80px;
      box-shadow: 0 0 10px black;

      img {
        width: 80px;
      }

      a {
        h2 {
          font-size: 20px;
        }
      }
    }

    article {
      div {
        h1 {
          margin: 0;
          font-size: 60px;
        }

        button {
          font-size: 35px;
          padding: 15px 20px;
          border-radius: 20px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .welcome {
    header {
      padding: 15px 5%;
    }
  }
}

@media (max-width: 550px) {
  .welcome {
    article {
      background-size: 1300px 973px;

      div {
        h1 {
          margin: 0;
          font-size: 11vw;
        }

        button {
          font-size: 7vw;
          padding: 3vw 4vw;
          border-radius: 4vw;
        }
      }
    }
  }
}
