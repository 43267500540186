.footer {
  div {
    background-color: var(--background-3);
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 15vw;
    }
  }

  section {
    background-color: var(--background-2);
    padding: 5%;
    display: grid;
    grid-template-columns: 50% 50%;

    a {
      text-decoration: none;
      transition: transform 0.5s;

      &:hover {
        transform: scale(1.1);
      }

      article {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30%;
        }

        p {
          font-size: 1.5vw;
          line-height: 1.2em;

          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .footer {
    div {
      img {
        width: 200px;
      }
    }

    section {
      a {
        article {
          img {
            width: 200px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .footer {
    div {
      img {
        width: 200px;
      }
    }

    section {
      grid-template-columns: 100%;

      a {
        width: 400px;
        margin: 0 auto;

        article {
          justify-content: flex-start;

          img {
            width: 200px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .footer {
    div {
      p {
        font-size: 4.5vw;
      }

      a {
        img {
          width: 40vw;
        }
      }
    }

    section {
      a {
        width: 100%;

        article {
          img {
            width: 40%;
          }

          p {
            font-size: 3.5vw;
          }
        }
      }
    }
  }
}
