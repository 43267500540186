.about-us {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5%;
  background-color: var(--background-1);

  img {
    width: 50%;
  }
}

@media (max-width: 750px) {
  .about-us {
    flex-direction: column;

    img {
      width: 80%;
    }
  }
}
