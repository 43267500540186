.benefits {
  background-color: var(--background-main);
  padding: 5%;
  text-align: center;

  div {
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    article {
      flex: 0 0 calc(25% - 20px);
      transition: transform 0.5s;

      &:hover {
        transform: scale(1.1);
        z-index: 2;
      }

      img {
        width: 80%;
      }

      h5 {
        line-height: 1.5vw;
        width: 12vw;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 1600px) {
  .benefits {
    div {
      article {
        h5 {
          line-height: 25px;
          width: 170px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .benefits {
    div {
      article {
        flex: 0 0 calc(30% - 20px);
      }
    }
  }
}

@media (max-width: 750px) {
  .benefits {
    div {
      article {
        flex: 0 0 calc(50% - 20px);

        h5 {
          width: 80%;
        }
      }
    }
  }
}
