@import "variables";

$color_title_1: #fac177;
$color_title_2: #fdbd12;
$color_title_3: #f6921e;
$color_title_4: #1c1a49;
$color_title_5: #f7981d;

$color_back_btn: #f6921e;
$color_btn: #1b1464;

$text_color: #1c1a49;

$color_back_input: #fac177;
$color_input: #1b1464;

/* ScrollBar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: $color_back_btn;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($color_back_btn, 15%);
}

@font-face {
  font-family: Biko-bolder;
  src: url("bikoBolder.otf") format("opentype");
}

@font-face {
  font-family: Biko-bold;
  src: url("bikoBold.otf") format("opentype");
}

@font-face {
  font-family: Biko;
  src: url("bikoRegular.otf") format("opentype");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Biko', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

h1 {
  color: $color_title_1;
  font-size: 4vw;
  margin: 1vw 0;
}

h2 {
  color: $color_title_2;
  font-size: 2.3vw;
}

h3 {
  font-family: 'Biko-bold', sans-serif;
  color: $color_title_3;
  font-size: 3vw;
}

h4 {
  font-family: 'Biko-bold', sans-serif;
  color: $color_title_4;
  font-size: 3vw;
}

h5 {
  color: $color_title_1;
  font-size: 1.3vw;
}

p {
  color: $text_color;
  margin: 2vw 0;
}

form input {
  background: $color_back_input;
  color: $color_input;
  font-size: 1.5vw;
}

button,
p {
  font-size: 2vw;
}

button {
  font-family: 'Biko-bolder', sans-serif;
  background-color: $color_back_btn;
  color: $color_btn;
  cursor: pointer;
}

form input,
button {
  border-radius: 1vw;
  border: none;
  padding: 1vw 1.5vw;
}

form button {
  width: 45%;
}

button:hover {
  background: darken($color_back_btn, 15%);
}

.bold {
  font-family: 'Biko-bold', sans-serif;
}

@media (max-width: 1600px) {
  button {
    font-size: 30px;
  }

  form input,
  button {
    border-radius: 15px;
    padding: 15px;
    margin: 10px 0;
  }

  p,
  form input {
    font-size: 24px;
  }

  h3,
  h4 {
    font-size: 30px;
  }

  h5 {
    color: $color_title_1;
    font-size: 21px;
  }
}

@media (max-width: 750px) {
  button {
    font-size: 24px;
  }

  form input,
  button {
    border-radius: 15px;
    padding: 15px;
    margin: 10px 0;
  }

  p,
  form input {
    font-size: 18px;
  }

  h3,
  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 14px;
  }
}
