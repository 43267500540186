.form-screen {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5%;
  background-color: var(--background-main);

  img {
    width: 30%;
    transition: transform 0.5s;
  }

  form {
    width: 50%;
    display: flex;
    flex-direction: column;
    transition: transform 1s, width 1s;

    * {
      margin: 1vw 0;
    }

    h2 {
      font-size: 3vw;
      line-height: 1.1em;
      a {
        color: white;
        margin-left: 0.7vw;
        text-decoration: none;
      }
    }

    span {
      margin: 0;
      color: #a63a00;
      margin-left: 1vw;
      font-size: 1vw;
    }

    .bad-input {
      box-shadow: 0 0 0.7vw #ff5900;
    }

    input {
      width: 100%;
    }
  }
}

@media (max-width: 1600px) {
  .form-screen {
    form {
      h2 {
        font-size: 40px;

        a {
          margin-left: 6px;
        }
      }

      span {
        margin-left: 20px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 750px) {
  .form-screen {
    flex-direction: column-reverse;

    img {
      width: 40%;
    }

    form {
      width: 100%;
      flex-direction: column;

      h2 {
        font-size: 30px;
      }

      .bad-input {
        box-shadow: 0 0 10px red;
      }

      * {
        margin: 10px 0;
      }

      span {
        margin-left: 18px;
      }
    }
  }
}
