.application-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--background-2);
  padding: 5%;

  img {
    width: 40%;
  }

  div {
    margin-left: 5%;

    article {
      padding: 2%;

      h4 {
        color: var(--color-1);
      }

      ul {
        list-style: none;

        li {
          font-size: 1vw;
          color: #1c1a49;
          margin: 1vw 0;

          &::before {
            content: "\2022";
            color: var(--color-1);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .application-info {
    div {
      article {
        ul {
          li {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .application-info {
    flex-direction: column;

    img {
      width: 100%;
    }

    div {
      article {
        ul {
          li {
            margin: 2vw 0;
          }
        }
      }
    }
  }
}
